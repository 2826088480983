.login-left-part h1 {
  font-weight: 300;
}

.login-page {
  height: 100vh;
  overflow: hidden;
}

.loginScreenLinkedin {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 99;
  background: #fff;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  margin-top: 0 !important;
  display: none;
  justify-content: center;
  align-items: center;
}
.loginScreenLinkedin.active {
  display: flex;
}

/* From Register.css */
.scrollableCls {
  height: 97vh;
  overflow-y: auto;
}
/* From Register.css */
.social-Login {
  max-width: 250px;
  margin: 30px auto 0 auto;
}
/* From Register.css */
.btn-linkedin {
  display: inline-block;
  color: #fff !important;
  background: #2977b5;
  border-radius: 6px;
  padding: 6px 28px;
  font-size: 14px;
  font-weight: 600;
  margin-right: 10px;
  border: 0;
}
/* From Register.css */
.centerLine {
  margin: 22px auto;
  text-align: center;
  position: relative;
  max-width: 450px;
}
/* From Register.css */
.centerLine:before {
  content: "";
  width: 46%;
  background: rgba(0, 0, 0, 0.2);
  height: 1px;
  position: absolute;
  left: 0px;
  top: 50%;
}
/* From Register.css */
.centerLine:after {
  content: "";
  width: 46%;
  background: rgba(0, 0, 0, 0.2);
  height: 1px;
  position: absolute;
  right: 0px;
  top: 50%;
}
