@media only screen and (max-width: 1440px) {
  .ais-Menu-searchBox .ais-SearchBox-form,
  .ais-RefinementList-searchBox .ais-SearchBox-form {
    height: 2.6rem;
    width: 10.51rem;
  }
}

@media (min-width: 992px) {
  .logo-admin {
    padding: 5px 10px 0 !important;
  }
  .main-menu .mainMenuUL {
    padding: 0px 20px 0 !important;
    width: 220px !important;
  }
  .main-menu ul li a {
    padding: 12px 0 12px 5px !important;
  }

  .content-wrapper {
    padding-left: 215px !important;
  }

  .userProfileWrapper {
    width: 220px !important;
  }
}

@media (min-width: 1281px) {
  .logo-admin {
    padding: 5px 25px 0 !important;
  }

  .main-menu .mainMenuUL {
    padding: 0px 25px 0 !important;
    width: 250px !important;
  }

  .main-menu ul li a {
    padding: 12px 0 12px 15px !important;
  }

  .content-wrapper {
    padding-left: 244px !important;
  }

  .userProfileWrapper {
    width: 250px !important;
  }
}
/* ------------------- From here adding all the media query from App.css */
@media (max-width: 1950px) {
  .w-300 {
    width: 339px;
  }
}

@media (max-width: 2500px) {
  .conti-new {
    width: 500px;
    margin-left: 120px;
  }

  .landing-banner,
  .home-banner {
    min-height: 601px;
  }

  .user-mg-page .comon-table-divs th:last-child,
  .user-mg-page .comon-table-divs tr td:last-child {
    width: 62px !important;
  }

  .user-mg-page .comon-table-divs th:nth-child(5),
  .user-mg-page .comon-table-divs tr td:nth-child(5) {
    width: 180px;
  }

  .user-mg-page .comon-table-divs th:nth-child(4),
  .user-mg-page .comon-table-divs tr td:nth-child(4) {
    width: 180px;
  }

  .user-mg-page .comon-table-divs th:nth-child(3),
  .user-mg-page .comon-table-divs tr td:nth-child(3) {
    width: 180px;
  }

  .expert-cell-page .comon-table-divs th:first-child,
  .expert-cell-page .comon-table-divs tr td:first-child {
    width: 460px;
  }

  .sp-hr {
    margin-left: 55px;
  }

  .sp-hr {
    margin-left: 80px;
  }

  .search-section-optios {
    padding-left: 80px;
  }

  .request-body-sec {
    margin-bottom: 50px;
  }

  .right-small-icons li {
    align-items: baseline;
  }

  .right-small-icons .red-icon {
    width: 41px;
  }

  .projects-details-page .right-section-div {
    background: none;
    box-shadow: none;
    border: none;
    padding: 0;
  }

  .projects-details-page .top-right-projects {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.08);
    box-shadow: 0px 5px 20px rgb(0 0 0 / 6%);
    border-radius: 12px;
    display: inline-block;
    width: 100%;
    padding: 0 25px 25px;
  }

  .projects-details-page .middle-right {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.08);
    box-shadow: 0px 5px 20px rgb(0 0 0 / 6%);
    border-radius: 12px;
    display: inline-block;
    width: 100%;
    padding: 25px;
  }

  .right-small-icons li span:last-child {
    font-size: 25px;
    font-weight: 600;
    color: #000;
  }

  .right-small-icons li span:last-child small {
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    color: #5c5f62;
  }

  /* 24-05-2022 */
  .table-sortable th {
    cursor: pointer;
  }

  .table-sortable .th-sort-asc::after {
    content: "\25b4";
  }

  .table-sortable .th-sort-desc::after {
    content: "\25be";
  }

  .table-sortable .th-sort-asc::after,
  .table-sortable .th-sort-desc::after {
    margin-left: 5px;
  }

  .table-sortable .th-sort-asc,
  .table-sortable .th-sort-desc {
    background: rgba(0, 0, 0, 0.1);
  }

  .projects-scope .top-ps-seection .nav-pills .nav-link {
    width: 164px;
    margin: auto;
    text-align: center;
  }

  .client-temas-div {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.08);
    box-shadow: 0px 5px 20px rgb(0 0 0 / 6%);
    border-radius: 12px;
    display: inline-block;
    width: 100%;
    padding: 25px;
  }

  .add-pls {
    background: #f8f8f8 !important;
    border: 1.5px solid #5746ec !important;
    border-radius: 10px !important;
    font-size: 14px !important;
    color: #5746ec !important;
  }

  .client-teams-ul-li ul {
    margin: 0;
  }

  .client-teams-ul-li ul li {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .projects-details-page .client-teams-ul-li .teams-li figure {
    float: left;
    margin: 0 10px 0 0 !important;
  }

  .client-teams-ul-li .teams-li h5 span {
    font-size: 13px;
    font-weight: 400;
    color: #666666;
    padding-top: 5px;
  }

  .client-teams-ul-li .teams-li figure {
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 50%;
  }

  .add-pls svg {
    width: 12px;
    height: 12px;
  }

  .sp-dots1 {
    width: 25px;
  }

  /* collaborate */

  .collaborate-page .top-ps-seection {
    width: 100%;
  }

  .comon-notes {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.08);
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    padding: 24px;
    display: inline-block;
    width: 100%;
    transition: all 0.5s;
    transform: scale(1);
  }

  .comon-notes .note-titel {
    color: #5746ec;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 10px;
    text-decoration: none;
    display: inline-block;
  }

  .loading-btn {
    background: #e7e7e7 !important;
    border-radius: 10px !important;
    width: 203px;
    height: 49px;
    line-height: 32px !important;
    color: #5c5f62 !important;
    font-weight: 500;
    font-size: 16px !important;
    transition: all 0.5s !important;
  }

  .collaborate-page .add-ps-btn {
    background: #f2f1ff !important;
  }

  .recente-vew-divs .comon-table-divs table {
    width: 100%;
  }

  .recente-vew-divs .comon-table-divs #ceo th {
    border-top: none !important;
  }

  .recente-vew-divs .comon-table-divs #ceo th:first-child {
    border: none !important;
  }

  .recente-vew-divs .collab-table #ceo th:first-child {
    border-bottom: 1px solid #d7d4d4 !important;
    margin-left: 3rem;
    max-width: 20.1rem;
  }

  .recente-vew-divs .comon-table-divs #ceo th:last-child {
    width: 260px !important;
  }

  .recente-vew-divs .comon-table-td {
    color: #5746ec;
    text-decoration: none;
  }

  .gp-anhcor a {
    margin: 0 15px;
  }

  .sp-td-sec {
    box-shadow: none !important;
    background: none !important;
  }

  /* .td-inside {
    height: 145px;
  } */
  .td-inside .icon-img {
    display: grid;
    align-content: center;
    margin-right: 10px;
  }

  .td-inside .bg-white {
    box-shadow: 0px 5px 20px rgb(0 0 0 / 6%);
    height: 145px;
    align-items: center;
    border-radius: 12px;
    padding: 0 20px;
    width: 100%;
    /* 28-04 */
  }

  .loading-btn:hover {
    background: #5746ec !important;
    color: #fff !important;
  }

  .loading-btn svg {
    width: 12px;
    height: 12px;
  }

  .comon-notes p {
    color: #202020;
    font-size: 15px;
    font-weight: 400;
  }

  .text-td {
    width: 379px;
  }

  .auto-td {
    width: 270px;
  }

  .commment-td {
    width: 120px;
  }

  .option-td {
    width: 223px;
  }

  .recente-vew-divs .w-300 {
    width: 339px;
  }

  .recente-vew-divs .w-160 {
    width: 270px;
  }

  .recente-vew-divs .w-1001 {
    width: 120px;
  }

  .comon-notes .auto-sec p {
    background: #f1f2f6;
    border-radius: 40px;
    width: auto;
    display: inline-block;
    height: 28px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    font-size: 14px;
    line-height: 26px;
  }

  .comon-notes .auto-sec p a {
    color: #5746ec;
    font-weight: 500;
    text-decoration: none;
  }

  /* 24-05-2022 */
  .list-btn3 a,
  .monr-btn {
    background: #ffffff !important;
    border: 1.2px solid rgba(0, 0, 0, 0.15) !important;
    border-radius: 8px !important;
    height: 35px;
    font-size: 12px !important;
    margin-right: 9px;
    margin-bottom: 10px;
    font-weight: 500 !important;
    color: #5c5f62 !important;
    height: 33px;
    font-size: 13px !important;
  }

  /* 27-05-06 */
  .comon-tabs-expert th:nth-child(3),
  .body-expart-div
    .expert-tables
    .comon-table-divs
    table
    tbody
    td:nth-child(3) {
    width: 400px;
  }

  .comon-tabs-expert th:nth-child(2),
  .body-expart-div
    .expert-tables
    .comon-table-divs
    table
    tbody
    td:nth-child(2) {
    width: 160px;
  }

  /* ends */
  .monr-btn {
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    display: inline-block;
    text-decoration: none !important;
    color: #000;
    margin-bottom: 0 !important;
    position: relative;
    top: -5px;
  }

  .top-ps-seection .nav-pills .nav-link {
    width: 162px;
  }

  .projects-pages .top-ps-seection .nav-pills .nav-link {
    width: 177px;
  }

  .expert-cell-page .top-ps-seection .nav-pills .nav-link {
    width: 267px;
    margin: 0 2px;
  }

  .w-300 {
    width: 293px;
  }

  .w-160 {
    width: 210px;
  }

  .w-1001 {
    width: 165px;
  }

  .w-2001 {
    width: 200px;
  }

  .w-66 {
    width: 60px !important;
  }

  .collab-table tr th:nth-child(2),
  .collab-table .bg-white > .div:first-child {
    width: 374px;
  }

  .expert-cell-page .comon-table-divs th:last-child,
  .expert-cell-page .comon-table-divs tr td:last-child {
    width: 155px;
  }

  .expert-cell-page .comon-table-divs th:nth-child(3),
  .expert-cell-page .comon-table-divs tr td:nth-child(3),
  .expert-cell-page .comon-table-divs th:nth-child(4) {
    width: 145px;
  }

  .comon-table-divs .dropdown .dropdown-item {
    font-size: 15px;
  }

  .user-mg-page .comon-table-divs th:last-child,
  .user-mg-page .comon-table-divs tr th:last-child:after {
    background: none !important;
  }

  .speacial-expert .modal-dialog {
    max-width: 550px !important;
  }

  .speacial-expert h2 {
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .speacial-expert hr {
    width: 94%;
  }

  .speacial-expert .modal-header {
    border: none !important;
    margin-bottom: 0;
    padding: 15px 20px 0;
  }

  .collab-table tr th:first-child,
  .collab-table .bg-white > .div:first-child {
    width: 430px;
  }

  .collab-table tr th:nth-child(2),
  .collab-table .bg-white > .div:first-child {
    width: 270px;
  }

  .recente-vew-divs .collab-table tr th:last-child,
  .recente-vew-divs .collab-table .bg-white > .div:last-child {
    width: 40px;
  }

  .call-history-table .td-inside .bg-white {
    width: 100%;
    padding: 0;
    align-items: flex-start;
    padding-top: 25px;
  }

  .call-history-table tr th:first-child {
    width: 557px;
  }

  .call-history-table .td-inside {
    margin-bottom: 25px;
  }

  .call-history-table .td-inside .bg-white > div:first-child {
    padding-left: 20px;
    width: 580px;
  }

  .call-history-table tr th:nth-child(2) {
    width: 150px;
  }

  .call-history-table tr th:nth-child(3) {
    width: 190px;
  }

  .call-history-table tr th:nth-child(4) {
    width: 190px;
  }

  .call-history-table .td-inside .bg-white > div:nth-child(2) {
    width: 155px;
    padding-left: 0;
  }

  .call-history-table .td-inside .bg-white > div:last-child {
    width: 201px;
    padding-left: 27px;
  }

  .call-history-table .commment-td {
    width: 155px;
  }

  .main-re-from-divs label {
    font-size: 16px;
  }
}

@media (max-width: 1440px) {
  .main-re-from-divs {
    margin-top: 110px !important;
  }

  .landing-sec-rg {
    margin-top: 30px !important;
  }

  .projects-pages .top-ps-seection .nav-pills .nav-link {
    width: 167px;
  }

  .expert-cell-page .top-ps-seection .nav-pills .nav-link {
    width: 251px !important;
    margin: 0 2px;
  }

  .projects-pages .w-1001 {
    width: 137px;
  }

  .expert-cell-page .comon-table-divs th:last-child,
  .expert-cell-page .comon-table-divs tr td:last-child {
    width: 97px;
  }

  .user-mg-page .comon-table-divs th:nth-child(4),
  .user-mg-page .comon-table-divs tr td:nth-child(4) {
    width: 119px;
  }

  .projects-scope .top-ps-seection .nav-pills .nav-link {
    width: 149px;
    margin: 0 5px;
  }

  .right-small-icons li span:last-child small {
    font-size: 9px;
  }
}

@media (max-width: 1400px) {
  .projects-pages .top-ps-seection .nav-pills .nav-link {
    width: 161px;
  }

  .w-1001 {
    width: 113px;
  }

  .expert-cell-page .comon-table-divs th:last-child,
  .expert-cell-page .comon-table-divs tr td:last-child {
    width: 65px;
  }

  .user-mg-page .comon-table-divs th:nth-child(3),
  .user-mg-page .comon-table-divs th:nth-child(4),
  .user-mg-page .comon-table-divs th:nth-child(5),
  .user-mg-page .comon-table-divs tr td:nth-child(3),
  .user-mg-page .comon-table-divs tr td:nth-child(4),
  .user-mg-page .comon-table-divs tr td:nth-child(5) {
    width: 148px;
  }

  .expert-cell-page .top-ps-seection .nav-pills .nav-link {
    margin: 0 2px;
    width: 241px;
  }

  .call-history-table .td-inside .bg-white {
    width: 100%;
    padding: 0;
    padding-top: 15px;
  }

  .call-history-table tr th:first-child {
    width: 442px;
  }

  .call-history-table .td-inside .bg-white > div:first-child {
    padding-left: 0px;
    width: 437px;
  }

  .call-history-table tr th:nth-child(2) {
    width: 150px;
  }

  .call-history-table tr th:nth-child(3) {
    width: 190px;
  }

  .w-1001 {
    width: 113px;
  }

  .expert-cell-page .comon-table-divs th:last-child,
  .expert-cell-page .comon-table-divs tr td:last-child {
    width: 65px;
  }

  .user-mg-page .comon-table-divs th:nth-child(3),
  .user-mg-page .comon-table-divs th:nth-child(4),
  .user-mg-page .comon-table-divs th:nth-child(5),
  .user-mg-page .comon-table-divs tr td:nth-child(3),
  .user-mg-page .comon-table-divs tr td:nth-child(4),
  .user-mg-page .comon-table-divs tr td:nth-child(5) {
    width: 148px;
  }

  .expert-cell-page .top-ps-seection .nav-pills .nav-link {
    margin: 0 2px;
    width: 241px;
  }

  .call-history-table .td-inside .bg-white {
    width: 100%;
    padding: 0;
    padding-top: 15px;
  }

  .call-history-table tr th:first-child {
    width: 442px;
  }

  .call-history-table .td-inside .bg-white > div:first-child {
    padding-left: 0px;
    width: 437px;
  }

  .call-history-table tr th:nth-child(2) {
    width: 150px;
  }

  .call-history-table tr th:nth-child(3) {
    width: 190px;
  }

  .call-history-table tr th:nth-child(4) {
    width: 190px;
  }

  .call-history-table .td-inside .bg-white > div:nth-child(2) {
    width: 150px;
    padding-left: 0;
  }

  .call-history-table .td-inside .bg-white > div:last-child {
    width: 201px;
    padding-left: 27px;
  }
}

@media (max-width: 1366px) {
  .projects-pages .top-ps-seection .nav-pills .nav-link {
    width: 158px;
  }

  .main-re-from-divs label {
    font-size: 14px;
  }

  .conti-new {
    width: 450px;
    margin-left: 110px;
  }

  .landing-banner h1 {
    font-size: 53px;
    font-weight: 600;
  }

  .landing-banner,
  .home-banner {
    min-height: 645px;
  }

  .user-mg-page .comon-table-divs th:nth-child(5),
  .user-mg-page .comon-table-divs tr td:nth-child(5) {
    width: 130px;
  }

  .user-mg-page .comon-table-divs th:nth-child(4),
  .user-mg-page .comon-table-divs tr td:nth-child(4) {
    width: 140px;
  }

  .user-mg-page .comon-table-divs th:nth-child(3),
  .user-mg-page .comon-table-divs tr td:nth-child(3) {
    width: 145px;
  }

  .user-mg-page .comon-table-divs th:last-child,
  .user-mg-page .comon-table-divs tr td:last-child {
    width: 74px !important;
  }

  .expert-cell-page .comon-table-divs th:first-child,
  .expert-cell-page .comon-table-divs tr td:first-child {
    width: 400px;
  }

  .expert-cell-page .comon-table-divs th:nth-child(3),
  .expert-cell-page .comon-table-divs tr td:nth-child(3),
  .expert-cell-page .comon-table-divs th:nth-child(4) {
    width: 130px;
  }

  /* 27-05-06 */
  .recente-vew-divs .comon-table-divs #ceo th:last-child {
    width: 180px !important;
  }

  .expert-cell-page .top-ps-seection .nav-pills .nav-link {
    width: 238px;
    margin: 0 2px;
  }

  /* .expert-cell-page .comon-table-divs th:last-child,
  .expert-cell-page .comon-table-divs tr td:last-child {
    width: 131px;
  } */
  .user-mg-page .comon-table-divs th:last-child,
  .user-mg-page .comon-table-divs tr td:last-child {
    width: 81px;
  }

  /* .collab-table tr th:first-child,
  .collab-table .bg-white > .div:first-child {
    width: 390px;
  } */
  .collab-table tr th:nth-child(2),
  .collab-table .bg-white > .div:first-child {
    width: 229px;
  }

  .td-inside .bg-white {
    width: 89%;
  }

  .call-history-table .td-inside .bg-white {
    width: 100%;
    padding: 0;
    padding-top: 24px;
  }

  .call-history-table tr th:first-child {
    width: 442px;
  }

  .call-history-table .td-inside .bg-white > div:first-child {
    padding-left: 0px;
    width: 437px;
  }

  .call-history-table tr th:nth-child(2) {
    width: 150px;
  }

  .call-history-table tr th:nth-child(3) {
    width: 190px;
  }

  .call-history-table tr th:nth-child(4) {
    width: 190px;
  }

  .call-history-table .td-inside .bg-white > div:nth-child(2) {
    width: 150px;
    padding-left: 0;
  }

  .call-history-table .td-inside .bg-white > div:last-child {
    width: 201px;
    padding-left: 27px;
  }

  .call-history-table .commment-td {
    width: 160px;
  }

  .collab-table .td-inside .bg-white {
    width: 82%;
    height: 116px;
  }

  .comon-tabs-expert th:nth-child(3),
  .body-expart-div
    .expert-tables
    .comon-table-divs
    table
    tbody
    td:nth-child(3) {
    width: 320px;
  }

  .comon-tabs-expert th:nth-child(2),
  .body-expart-div
    .expert-tables
    .comon-table-divs
    table
    tbody
    td:nth-child(2) {
    width: 120px;
  }

  /* ends */

  .projects-scope .top-ps-seection .nav-pills .nav-link {
    width: 145px;
    margin: auto;
    text-align: center;
  }

  .right-small-icons li span:last-child {
    font-size: 17px;
    font-weight: 600;
    color: #000;
  }

  .right-small-icons li span:last-child small {
    font-size: 11px;
    font-weight: 400;
    text-transform: uppercase;
    color: #5c5f62;
  }

  .comon-notes .auto-sec p {
    background: #f1f2f6;
    border-radius: 40px;
    /* width: 111px; */
    display: inline-block;
    height: 28px;
    text-align: center;
    font-size: 11px;
    line-height: 27px;
  }

  /* .select-option {
    margin-left: 53px;
  } */

  .sp-hr {
    margin-left: 53px;
  }

  .sp-hr {
    margin-left: 53px;
  }

  .body-expart-div {
    padding-left: 53px;
  }

  .search-section-optios {
    padding-left: 53px;
  }

  .list-btn3 a,
  .monr-btn {
    background: #ffffff !important;
    border: 1.2px solid rgba(0, 0, 0, 0.15) !important;
    border-radius: 8px !important;
    height: 35px;
    font-size: 12px !important;
    margin-right: 3px;
  }

  .top-ps-seection .nav-pills .nav-link {
    width: 143px;
  }

  .w-300 {
    width: 257px;
  }

  .w-160 {
    width: 180px;
  }

  .w-1001 {
    width: 148px;
  }

  .w-2001 {
    width: 170px;
  }

  .w-66 {
    width: 66px !important;
  }
}

/* landing page design */
@media (min-width: 1180px) {
  .projects-scope .top-ps-seection .nav-pills .nav-link {
    width: 122px;
    margin: 0 2px;
  }
}

@media (min-width: 992px) {
  .sidebar-menu {
    background: #fff;
    box-sizing: border-box;
    height: 100vh;
    left: 0;
    margin-top: 0;
    overflow: hidden;
    position: fixed;
    top: 0;
    width: 221px;
    /* padding: 25px 25px 0; */
  }
}

@media (max-width: 1180px) {
  .comon-divs-re {
    height: auto;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .call-history-table tr th:first-child {
    width: 212px;
  }

  .call-history-table .td-inside .bg-white > div:first-child {
    padding-left: 21px;
    width: 234px;
  }

  .nes-tabs ul li {
    margin: 10px 0;
  }

  .td-inside .bg-white,
  .td-inside {
    height: auto;
  }

  .comon-history-expert {
    padding: 30px;
    height: auto;
  }

  .detsils-secch-comon ul li {
    margin: 10px 0;
  }

  .serhisiroy-right .edite-bnt {
    width: 100%;
    margin-left: 0;
  }

  .comon-divs-re h5 {
    font-size: 16px;
  }

  .body-expart-div .nav-pills .nav-link {
    margin-bottom: 0 !important;
  }

  .next-list li {
    margin-bottom: 10px;
  }

  .top-bar-menu .serch-sec-div {
    width: 100%;
  }

  .sp-hr {
    margin-left: 23px;
  }

  .body-expart-div {
    padding-left: 23px;
  }

  .main-menu ul li a {
    font-size: 13px;
  }

  .notes-d-section .comon-notes {
    margin-bottom: 25px;
  }

  .expert-cell-page .top-ps-seection .nav-pills .nav-link {
    width: 203px !important;
    width: 166px !important;
    margin: 0 3px;
  }

  .expert-cell-page .comon-table-divs,
  .projects-pages .comon-table-divs {
    width: 100%;
    overflow: auto;
  }

  .expert-cell-page .comon-table-divs table {
    table-layout: fixed;
    width: 990px;
  }

  .projects-pages .comon-table-divs table {
    table-layout: fixed;
    width: 990px;
  }

  .projects-pages .top-ps-seection .nav-pills .nav-link {
    width: 116px;
    width: 103px;
    margin-bottom: 0;
  }

  .landing-page-d1 .login-left-part {
    background-size: cover;
    min-height: 100%;
  }
}

@media (max-width: 1024px) {
  .select-option,
  .sp-hr {
    margin-left: 0;
  }

  .body-expart-div {
    padding-left: 0;
  }

  .sidebar-menu {
    width: 21%;
  }
}

@media (max-width: 900px) {
  .sidebar-menu {
    display: none;
  }

  .landing-page-d1 .main-re-from-divs {
    margin: auto;
    width: 80%;
  }

  .comon-card-n {
    margin-bottom: 30px;
  }

  .new-register-page .main-re-from-divs,
  .new-add-rg2 .main-re-from-divs {
    margin: auto;
    width: 80%;
  }

  .new-register-page .conti-new {
    width: 80%;
    margin: auto;
    display: table;
  }

  .mob-call-sec .div-pogress h5 {
    font-size: 14px;
    color: #bbb;
  }

  .mob-call-sec .div-pogress h5 span {
    font-size: 18px;
    color: #000;
    font-weight: 600;
  }

  .mob-call-sec .date h5 {
    color: #5746ec;
    text-transform: capitalize;
  }

  .titels-new {
    font-size: 20px;
  }

  .mob-call-sec .nubers-div1 h5 {
    text-transform: capitalize;
    color: #000;
  }

  .detsils-secch-comon {
    width: 100%;
  }

  .landing-page-d1 .main-re-from-divs .logo,
  .new-register-page .main-re-from-divs .logo,
  .new-add-rg2 .main-re-from-divs .logo {
    margin-left: 0;
  }

  .comon-history-expert {
    width: 100%;
    margin: auto;
    height: auto;
    padding: 30px;
  }

  .serhisiroy-right:after {
    display: none;
  }

  .serhisiroy-right h5 {
    text-align: center;
  }

  .new-register-page .back-rgs {
    left: 8%;
  }

  .back-new-fun {
    left: 8%;
  }

  .serhisiroy-right .edite-bnt {
    margin: auto;
    width: 100%;
  }

  .left-hisroty-expert figure {
    width: 100%;
    text-align: left;
  }

  .detsils-secch-comon ul li {
    margin: 15px 0;
  }

  .serhisiroy-right {
    margin-top: 30px;
  }

  .mbile-divs-table {
    background: #ffffff;
    box-shadow: 0px 5px 20px rgb(0 0 0 / 6%);
    border-radius: 12px;
    padding: 30px;
    margin-top: 30px;
    display: inline-block;
    position: relative;
  }

  .inside-support,
  .news-section,
  .faq-section {
    width: 100%;
  }

  .body-expart-div h2 {
    font-size: 25px;
  }

  .converstion-divs .nav .nav-link h5 span {
    color: #5c5f62;
    font-weight: 400;
    font-size: 11px;
  }

  .expert-cell-page .top-ps-seection .nav-pills .nav-link {
    width: 165px;
    margin: 0 2px;
  }

  .main-menu ul li a {
    font-size: 15px;
  }

  .comon-divs-re h5 {
    font-size: 18px;
  }

  .content-wrapper {
    padding-left: 0;
  }

  .top-bar-menu .serch-sec-div {
    width: 100%;
  }

  .mobile-serch-sec-div {
    border: solid 1px #f33636 !important;
    height: 43px !important;
    border-radius: 10px !important;
    padding-top: 0;
    padding-right: 10px;
    padding-bottom: 0;
    padding-left: 0;
    width: 100%;
    overflow: hidden;
    margin-bottom: 20px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff !important;
  }

  .mobile-serch-sec-div .form-control {
    border: none !important;
  }

  .mobile-serch-sec-div svg {
    color: #f33636;
  }

  .right-menu-sec > div:nth-child(2) {
    display: none !important;
  }

  .login-left-part,
  .regitser-page-div {
    padding-left: 30px;
  }

  .regitser-page-div {
    padding-right: 30px;
  }

  .select-option,
  .sp-hr,
  .body-expart-div {
    padding-left: 0;
  }

  .select-option,
  .sp-hr {
    margin-left: 0;
  }

  .comon-divs-re {
    height: auto;
    padding-top: 30px;
    padding-bottom: 30px;
    width: 100%;
  }

  .mobile-fill-width {
    width: 100%;
  }

  .mobile-canvas .offcanvas-title {
    visibility: hidden;
  }

  .comon-select {
    margin-bottom: 25px;
  }

  .next-list li {
    margin-bottom: 10px;
  }

  .tp-list1 {
    margin: 25px 0;
  }

  .new-reguest {
    line-height: 30px !important;
  }

  .right-menu-sec {
    justify-content: space-between;
  }

  .left-section-divs,
  .right-section-div {
    width: 100%;
  }

  .search-section-optios {
    padding-left: 0;
  }

  .search-section-optios .comon-ser-btn {
    margin-bottom: 10px;
  }

  .tab-content {
    overflow: auto;
    width: 100%;
  }

  .comon-table-divs table {
    table-layout: fixed;
    width: 1090px !important;
  }

  .top-ps-seection .nav {
    background: #e7e7e7;
    border-radius: 10px;
    padding: 5px 5px 1px;

    justify-content: space-between;
  }

  .collab-table {
    overflow: auto;
    width: 100%;
  }

  .collab-table .table {
    table-layout: fixed;
    width: 800px !important;
  }

  .request-page {
    width: 100%;
  }

  /* 02-06-2022 */
  .login-page-div {
    position: relative;
    width: 50%;
    padding-bottom: 50px;
  }

  .mobile-canvas .offcanvas-header,
  .mobile-canvas .logo-admin {
    display: none;
  }

  .mobile-canvas {
    top: 62px !important;
  }

  .dasb-div .select-option {
    width: 100%;
    overflow: auto;
  }

  .dasb-div .select-option .flex-column {
    flex-direction: row !important;
    width: 1366px;
  }

  .body-expart-div .nav-pills .nav-link {
    font-size: 13px;
    padding: 8px;
  }

  .body-expart-div .nav-item {
    margin: 0 8px;
  }

  .body-expart-div .ps-titel {
    font-size: 20px;
  }

  .projects-pages .top-ps-seection .nav,
  .projects-scope .top-ps-seection .nav {
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 5px 6px;
  }

  .projects-pages .top-ps-seection .nav-pills .nav-link,
  .projects-scope .top-ps-seection .nav-pills .nav-link {
    width: 89px !important;
    margin-bottom: 0;
  }

  .expert-cell-page .top-ps-seection .nav-pills .nav-link {
    width: 148px !important;
    margin-bottom: 0;
  }

  .projects-pages .nav-item:first-child,
  .projects-pages .nav-item:first-child {
    margin-left: 0;
  }

  .td-inside .d-flex {
    flex-wrap: wrap !important;
    width: 100% !important;
    justify-content: flex-start !important;
    height: auto !important;
    padding: 20px !important;
  }

  .td-inside .text-td {
    width: 100%;
  }

  .collaborate-page .td-inside {
    height: auto !important;
    margin-bottom: 30px;
    display: inline-block !important;
    width: 100%;
  }

  .file-btn {
    margin-right: 10px;
  }

  .body-expart-div h2 {
    font-size: 22px;
  }

  .user-mg-page h2 {
    margin-bottom: 0;
  }

  .top-ps-seection {
    width: 100%;
  }

  .support-btn {
    display: flex;
    flex-wrap: wrap !important;
    height: auto !important;
    padding: 20px !important;
    position: relative;
  }

  .support-btn > svg {
    position: absolute;
    right: 15px;
    top: 45px;
  }

  .support-page .accordion-button {
    font-size: 20px;
  }

  .support-page .accordion-button::after {
    width: 2.25rem;
  }

  .support-btn img {
    margin-bottom: 10px;
  }

  .request-body-sec ul.d-flex {
    flex-wrap: wrap !important;
  }

  .request-body-sec ul.d-flex li {
    width: 100%;
    margin-bottom: 15px;
  }

  .details-message-part .expert-tables .nav {
    flex-wrap: nowrap !important;
    justify-content: flex-start !important;
  }

  .expert-tables {
    width: 100%;
    overflow: auto;
  }

  .message-text-area .text-area-type {
    width: 90%;
  }

  .search-mobile-s {
    display: block !important;
  }

  .mboile-logo {
    text-decoration: none;
    height: 32px;
    overflow: hidden;
    top: 2px;
    position: relative;
    display: inline-block;
  }

  .comon-re-texts {
    display: none;
  }

  .expert-login-page .landing-sec-rg {
    display: table;
    width: 80%;
    margin: auto;
  }

  .expert-login-page .logo {
    margin-left: 0;
  }

  .all-complains {
    width: 100%;
  }

  .payment-details-2 {
    width: 100%;
  }

  .comon-payments-ex {
    width: 100%;
  }
}

@media (max-width: 667px) {
  .comon-select {
    margin-bottom: 25px;
  }

  .mob-call-sec .date h4 {
    color: #5c5f62;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 500;
  }

  .comon-payments-ex .dropdown {
    position: absolute;
    top: 28px;
    right: 10px;
  }

  .request-body-sec ul li {
    margin-bottom: 10px;
  }

  .jodit-toolbar__box:not(:empty) {
    width: 92% !important;
  }

  .md-titel-link {
    display: block;
  }

  .request-body-sec ul li span,
  .request-body-sec .com-li li span:first-child {
    margin-right: 10px;
  }

  .online-st svg {
    color: #299c46;
  }

  .expert-login-page .main-re-from-divs {
    padding-left: 0;
    padding-right: 0;
  }

  .landing-page-d1 .main-re-from-divs {
    padding-right: 15px;
  }

  .all-chat-divs .menmber-text,
  .all-chat-divs .admin-text {
    width: 105%;
  }

  .expert-cell-page .comon-table-td {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .all-chat-divs {
    padding: 0;
  }

  .comon-toggle {
    flex-direction: row-reverse;
  }

  .cm-modal-all .submit-bn {
    width: 100%;
  }

  .comon-toggle p {
    position: relative;
    left: 10px;
  }

  .mob-call-sec .div-pogress h4 {
    color: #5c5f62;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 500;
  }

  .mob-call-sec .date h5 {
    font-size: 18px;
  }

  .left-pm {
    align-items: baseline;
  }

  .mob-call-sec .nubers-div1 h5 {
    font-size: 18px;
  }

  .mob-call-sec .comon-table-td {
    font-size: 20px;
  }

  .mob-call-sec .nubers-div1 h4 {
    color: #5c5f62;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 500;
  }

  .all-complains {
    width: 100%;
  }

  .serhisiroy-right {
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    padding-top: 15px;
  }

  .payment-details-2 {
    width: 100%;
  }

  .left-pm figure {
    margin-bottom: 15px;
  }

  .comon-payments-ex {
    height: auto;
  }

  .comon-re-texts {
    display: none;
  }

  .left-hisroty-expert figure {
    width: 100%;
    text-align: left;
  }

  .expert-login-page .landing-sec-rg {
    display: table;
    width: 80%;
    margin: auto;
  }

  .expert-login-page .row > div:nth-child(2) {
    padding-right: 0;
  }

  .expert-login-page .logo {
    margin-left: 0;
  }

  .landing-page-d1 .logo {
    margin-left: 0;
    border-bottom: solid 1px rgba(0, 0, 0, 15%);
    padding-bottom: 20px;
    text-align: center;
  }

  .converstion-divs .nav .nav-link h5 span {
    color: #5c5f62;
    font-weight: 400;
    font-size: 13px;
  }

  .body-expart-div .nav-pills .nav-link {
    margin-bottom: 15px;
  }

  .top-ps-seection .nav {
    flex-direction: column;
  }

  .body-expart-div .nav-pills .nav-link {
    width: 100%;
  }

  .body-expart-div .nav-pills .nav-link.active,
  .body-expart-div .nav-pills .nav-link:hover,
  .body-expart-div .nav-pills .show > .nav-link {
    width: 100%;
  }

  .body-expart-div .nav-pills .nav-link {
    margin-bottom: 15px;
  }

  .next-list li {
    margin-bottom: 10px;
  }

  .tp-list1 {
    margin: 25px 0;
  }

  .regitser-page-div {
    padding-right: 0;
    padding-left: 0;
  }

  .notes-d-section .comon-notes {
    margin-bottom: 25px;
  }

  .request-page {
    width: 100%;
    text-align: left;
  }

  .expert-login-page .landing-sec-rg {
    display: table;
    width: 80%;
    margin: auto;
  }

  .expert-login-page .row > div:nth-child(2) {
    padding-right: 0;
  }

  .expert-login-page .logo {
    margin-left: 0;
  }

  .landing-page-d1 .logo {
    margin-left: 0;
    border-bottom: solid 1px rgba(0, 0, 0, 15%);
    padding-bottom: 20px;
    text-align: center;
  }

  .converstion-divs .nav .nav-link h5 span {
    color: #5c5f62;
    font-weight: 400;
    font-size: 13px;
  }

  .body-expart-div .nav-pills .nav-link {
    margin-bottom: 15px;
  }

  .top-ps-seection .nav {
    flex-direction: column;
  }

  .body-expart-div .nav-pills .nav-link {
    width: 100%;
  }

  .body-expart-div .nav-pills .nav-link.active,
  .body-expart-div .nav-pills .nav-link:hover,
  .body-expart-div .nav-pills .show > .nav-link {
    width: 100%;
  }

  .body-expart-div .nav-pills .nav-link {
    margin-bottom: 15px;
  }

  .next-list li {
    margin-bottom: 10px;
  }

  .tp-list1 {
    margin: 25px 0;
  }

  .regitser-page-div {
    padding-right: 0;
    padding-left: 0;
  }

  .notes-d-section .comon-notes {
    margin-bottom: 25px;
  }

  .request-page {
    width: 100%;
  }

  .login-page-div {
    height: 100%;
    width: 100%;
  }

  .login-page-div {
    height: 100%;
    width: 100%;
  }

  .login-page-div .inside-login {
    margin-bottom: 50px;
  }

  .mbile-divs-table {
    background: #ffffff;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    padding: 30px;
    margin-top: 30px;
    display: inline-block;
    position: relative;
  }

  .mbile-divs-table h5 {
    text-transform: uppercase;
    color: #5c5f62;
  }

  .number-list {
    justify-content: flex-start;
  }

  .comon-table-td {
    color: #000000;
    text-decoration: none;
  }

  .dp-mobiles {
    position: absolute;
    right: 20px;
    top: 37px;
  }
}

@media (max-width: 480px) {
  .login-page-div {
    height: auto;
  }

  .login-page-div .inside-login {
    margin-bottom: 0;
  }

  .main-re-from-divs {
    width: 100% !important;
    padding-left: 25px;
    padding-right: 25px;
    margin-top: 60px !important;
  }

  .back-new-fun,
  .back-rgs {
    top: 84px;
    left: 6%;
  }

  .new-register-page .back-rgs {
    left: 3% !important;
  }

  .comon-dt-picker {
    margin-top: 10px;
  }

  .last-details h5 {
    text-align: left !important;
  }

  .dasb-div .select-option .flex-column {
    flex-direction: row !important;
    width: 50rem;
  }

  .dasb-div .select-option .flex-column > div {
    padding-right: 3px !important;
  }

  .next-list li {
    font-size: 16px;
    color: #5c5f62;
  }

  .next-list ul {
    display: flex;
    flex-wrap: wrap;
  }

  .next-list li {
    display: none;
  }

  .more-ex-btn {
    width: 100%;
    /* margin-top: 15px; */
    margin-left: 0;
  }

  .tp-list1 li {
    font-size: 16px;
    margin-bottom: 10px;
    flex-wrap: wrap;
  }

  .one-ex-btn {
    width: 100%;
  }

  .notes {
    flex-wrap: wrap;
  }

  .speacial-expert h2 {
    font-size: 22px;
  }

  .notes span:last-child {
    padding-left: 0;
    margin-left: 0;
    margin-top: 8px;
  }

  .next-list li a {
    text-decoration: none;
    color: #5c5f62;
    font-size: 16px;
    font-size: 16px;
    color: #5c5f62;
  }

  .next-list ul {
    display: flex;
    flex-wrap: wrap;
  }

  .next-list li {
    display: none;
  }

  .more-ex-btn {
    width: 100%;
    /* margin-top: 15px; */
    margin-left: 0;
  }

  .tp-list1 li {
    font-size: 16px;
    margin-bottom: 10px;
    flex-wrap: wrap;
  }

  .one-ex-btn {
    width: 100%;
  }

  .notes {
    flex-wrap: wrap;
  }

  .speacial-expert h2 {
    font-size: 22px;
  }

  .notes span:last-child {
    padding-left: 0;
    margin-left: 0;
    margin-top: 8px;
  }

  .next-list li a {
    text-decoration: none;
    color: #5c5f62;
    font-size: 16px;
  }

  .next-list li:first-child,
  .next-list li:last-child {
    display: block;
  }

  .add-btn-divs {
    margin-top: 10px;
  }

  .new-register-page .row > div:nth-child(2) {
    padding: 0;
  }

  .re-spi-logo {
    border-bottom: solid 1px rgba(0, 0, 0, 15%);
    padding-bottom: 20px;
    width: 100%;
    text-align: center;
    margin: auto;
    margin-top: 15px;
  }

  .new-register-page .conti-new {
    margin-bottom: 25px;
    width: 86%;
  }

  .regitser-page-div {
    width: 100%;
  }

  /* .regitser-page-div .logo,
  .login-page-div .logo,
  .landing-sec-rg .logo {
    margin: auto;
    text-align: center;
    border-bottom: solid 1px rgba(0, 0, 0, 15%);
    padding-bottom: 16px;
  } */
  .login-page-div {
    margin-top: 23px;
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
  }

  .login-btn1 {
    display: block;
  }

  .regitser-page-div .inside-login p {
    font-size: 20px;
  }

  .comon-fild label,
  .regitser-page-div label {
    color: #000000;
  }

  .regitser-page-div .form-control::-webkit-input-placeholder {
    color: #000;
  }

  .inside-login h4 {
    text-align: center;
    font-size: 26px;
    font-weight: 500;
    margin-top: 30px;
  }

  /* .login-page-div{
     transform: none;
     top:0;
     left: 0;
     right: 0;
     position: relative;
   } */

  .register-btn {
    display: block;
    font-size: 20px;
  }

  .inside-login p,
  .landing-sec-rg p {
    font-size: 20px;
  }

  .main-re-from-divs label {
    font-size: 18px;
  }

  .main-re-from-divs .form-control::-webkit-input-placeholder {
    color: #000;
  }

  .expert-login-page .landing-sec-rg {
    width: 100%;
  }

  .landing-sec-rg {
    margin-top: 23px !important;
  }

  .expert-login-page .login-fm-div {
    padding-left: 25px;
    padding-right: 15px;
  }

  .comon-select {
    margin-bottom: 25px;
  }

  .body-expart-div .nav-pills .nav-link {
    margin-bottom: 15px;
  }
}

@media (max-width: 576px) {
  .serch-sec-div {
    max-width: 100rem;
  }

  .comon-fild h4 {
    font-size: 24px !important;
    font-weight: 500 !important;
  }

  .logo-bottom {
    color: "#5746ec";
    font-size: 14px !important;
  }
}

@media screen and (max-width: 1199px) {
  .main-menu {
    height: auto;
    overflow-y: scroll;
    top: 3.5rem;
    bottom: 0rem;
    padding: 10px 0px;
    top: 0;
  }

  .main-menu .mainMenuUL {
    padding: 0 !important;
  }

  .main-menu ul li {
    margin: 0 !important;
  }

  .logo-admin {
    padding: 5px 10px 0;
  }
}

@media (min-width: 1280px) {
  .select-option {
    max-width: 100% !important;
  }

  .right-menu-sec ul li {
    /* padding: 0.5rem 0.5rem !important; */
    margin-right: 7px;
  }

  .dataResult {
    width: 77% !important;
  }
}

@media (max-width: 992px) {
  .request-body-sec {
    margin-top: 10px;
  }

  .table tr td .createdDate {
    width: 10rem;
  }

  /* .project-sec-2 {
    display: none !important;
  } */
}

@media (max-width: 768px) {
  .projects-scope .top-ps-seection {
    font-size: 5px;
    /* padding-top: 60px !important; */
  }

  .number-list span {
    width: 10px;
    margin-right: 5px;
  }

  .search-btn-back {
    font-size: 18px !important;
    font-weight: 500 !important;
    padding-left: 0px !important;
  }

  .messageScreen button {
    padding: 0.5rem 0.5rem;
  }

  .projects-pages .top-ps-seection .nav,
  .projects-scope .top-ps-seection .nav {
    flex-direction: row;
    justify-content: space-between;
    padding: 0 0 0;
  }

  .comon-table-td span {
    width: 10rem;
  }

  .notifications {
    padding: 5px 9px;
  }

  .notificationFrom {
    border: 1px solid transparent;
    width: 66%;
    padding-top: 2.5%;
  }

  .regitser-page-div {
    width: 90% !important;
  }

  .logo {
    margin: 10px 0 0 1px !important;
    display: inline-block;
  }

  .login-page-div {
    width: 90% !important;
  }
}

@media (max-width: 576px) {
  .table tr th {
    font-size: 5px;
  }

  .top-ps-seection a {
    margin-top: 10px;
  }

  .top-ps-seection button {
    margin-top: 0 !important;
  }

  .projects-pages .top-ps-seection .nav-pills .nav-link,
  .projects-scope .top-ps-seection .nav-pills .nav-link {
    width: 112px !important;
    margin-bottom: 0;
  }

  .number-list span {
    width: 10px;
    margin-right: 5px;
  }

  .top-ps-seection p {
    margin-top: 20px;
  }

  .notificationFrom {
    border: 1px solid transparent;
    width: 66%;
    padding-top: 20px;
    padding-left: 16px;
  }

  .notificationFrom h5 {
    font-size: 16px;
  }

  .main-menu {
    padding: 0;
  }

  .body-expart-div,
  .select-option,
  .customized-app {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .select-option {
    max-width: 100% !important;
  }

  .select-option .ais-RefinementList-list {
    width: 43.7% !important;
  }

  .dasb-div .select-option .flex-column {
    margin-top: 10px;
  }

  .top-bar-menu {
    padding-top: 0% !important;
    padding-bottom: 0% !important;
  }

  .hour span {
    font-size: 18px;
  }

  .new-reguest {
    font-size: 14px !important;
  }

  .body-expart-div h5 {
    font-size: 16px !important;
  }

  .totalExperience {
    font-size: 13px !important;
    margin-bottom: 0;
  }

  .text-body-small {
    font-size: 0.775rem !important;
  }

  /* .modal-backdrop {
    display: none !important;
  } */
  .regitser-page-div {
    width: 86% !important;
  }

  .logo {
    margin: 10px 0 0 1px !important;
    display: inline-block;
    margin-bottom: 10px !important;
  }

  .login-page-div {
    width: 86% !important;
  }

  .top-ps-seection .nav {
    margin-top: 15px !important;
    margin-bottom: 10px !important;
  }

  .dasb-div {
    padding-top: 2px !important;
    padding-bottom: 100px !important;
  }
  .createBttnNotes {
    position: absolute !important;
    z-index: 9;
    font-size: 11px;
    top: 37rem !important;
    right: 18px;
    z-index: 9;
    width: 120px;
  }

  .createBttnNotes button {
    font-size: 10px;
  }

  .mobile-canvas {
    width: 284px !important;
  }

  .messageEditorWrapper {
    background-color: rgb(255, 255, 255);
    position: absolute;
    bottom: 10px;
    width: 100% !important;
  }

  .bi-send {
    position: absolute;
    right: 10px;
    bottom: 10px;
    width: 50px;
    height: 50px;
    background: rgb(87, 70, 236);
    text-align: center;
    color: rgb(255, 255, 255);
    border-radius: 50%;
    line-height: 2.3 !important;
    cursor: pointer;
    z-index: 9999;
    top: 112px !important;
    /* right: 1px; */
    height: 30px !important;
    width: 30px !important;
  }

  .request-body-sec {
    margin-top: -20px !important;
  }

  .comon-tabs-expert .offcanvas-end {
    width: 95vw !important;
  }

  .shareableLink {
    font-size: 12px !important;
  }

  .inputGroups .dropdownBtn {
    font-size: 12px !important;
  }

  .accessInfo .s1E8jgYqDWXyHdyBw59R {
    font-size: 14px !important;
  }

  .Yourr_9w71MjtL3S9I4G .dropdownBtn {
    font-size: 14px !important;
  }

  .nameInfo span {
    font-size: 14px !important;
  }

  .ZP9BDnxHhGA9rSLoFsa3 .cDCnsp1vcViX1pVJM7MV {
    font-size: 14px !important;
  }

  .comon-divs-re .right-divs-2 {
    margin-top: 5px !important;
    /* margin-bottom: 5px !important; */
  }

  .tab-pane .offcanvas-end {
    width: 90vw !important;
  }

  .dasb-div .projects-pages2 {
    margin-top: 60px !important;
  }

  .top-ps-seection .nav {
    padding: 1px 1px 1px !important;
  }

  .body-expart-div h2 {
    white-space: pre-line !important;
    font-size: 18px !important;
  }

  .table tr td {
    min-width: 4rem !important;
  }

  .project-sec-1 {
    display: none;
  }

  .notificationContainer {
    width: 100% !important;
  }

  .noResultFound img {
    width: 35% !important;
    height: 35% !important;
  }

  .noResultFound h3 {
    font-size: 14px !important;
  }

  .inputs-search {
    margin-top: 8px;
  }
}

@media screen and (min-width: 100px) and (max-width: 480px) {
  .dasb-div .select-option .flex-column {
    margin-top: 0;
  }

  .findExperts .find_companyName {
    font-size: 18px !important;
    white-space: break-spaces !important;
  }

  .findExperts .linkH5 {
    white-space: break-spaces !important;
  }

  .tp-list1 li {
    font-size: 15px !important;
    margin-bottom: 10px;
    flex-wrap: wrap;
  }

  .findExperts .expertRate {
    font-size: 22px !important;
  }

  .hour span {
    font-size: 18px;
  }

  .jodit-toolbar__box:not(:empty) {
    width: 92% !important;
  }

  .projects-pages .top-ps-seection .nav-pills .nav-link,
  .projects-scope .top-ps-seection .nav-pills .nav-link {
    width: 100px !important;
    margin-bottom: 0;
  }

  .noResultFound img {
    width: 90% !important;
    height: 90% !important;
  }

  /* .jodit-ui-group {
    display: flex !important;
    flex-wrap: nowrap !important;
} */
}

@media screen and (min-width: 100px) and (max-width: 430px) {
  .pos-relative .box {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
}

@media screen and (min-width: 1300px) and (max-width: 1366px) {
  .select-option {
    width: 100% !important;
  }

  .right-menu-sec ul li {
    padding: 0.5rem 0.5rem !important;
    margin-right: 7px;
  }

  .dataResult {
    width: 76.9% !important;
  }
}

@media screen and (min-width: 1500px) and (max-width: 1680px) {
  .select-option {
    max-width: 100% !important;
  }

  .dataResult {
    width: 82.2% !important;
  }
}

@media only screen and (max-width: 1440px) {
  .select-option {
    max-width: 100% !important;
  }
}

@media (min-width: 1920px) {
  .select-option {
    max-width: 97.5% !important;
  }

  .dataResult {
    width: 84.2% !important;
  }
}

@media only screen and (min-width: 1300px) and (max-width: 1366px) {
  .right-menu-sec .nav-link {
    padding-left: 0;
  }
}

@media (max-width: 1950px) {
  .w-300 {
    width: 339px;
  }
}

@media (max-width: 2500px) {
  .conti-new {
    width: 500px;
    margin-left: 120px;
  }

  .landing-banner,
  .home-banner {
    min-height: 601px;
  }

  /* .top-bar-menu .serch-sec-div {
    width: 95%;
  } */
  .user-mg-page .comon-table-divs th:last-child,
  .user-mg-page .comon-table-divs tr td:last-child {
    width: 62px !important;
  }

  .user-mg-page .comon-table-divs th:nth-child(5),
  .user-mg-page .comon-table-divs tr td:nth-child(5) {
    width: 180px;
  }

  .user-mg-page .comon-table-divs th:nth-child(4),
  .user-mg-page .comon-table-divs tr td:nth-child(4) {
    width: 180px;
  }

  .user-mg-page .comon-table-divs th:nth-child(3),
  .user-mg-page .comon-table-divs tr td:nth-child(3) {
    width: 180px;
  }

  .expert-cell-page .comon-table-divs th:first-child,
  .expert-cell-page .comon-table-divs tr td:first-child {
    width: 460px;
  }

  .sp-hr {
    margin-left: 55px;
  }

  .sp-hr {
    margin-left: 80px;
  }

  .search-section-optios {
    padding-left: 80px;
  }

  .request-body-sec {
    margin-bottom: 50px;
  }

  .right-small-icons li {
    align-items: baseline;
  }

  .right-small-icons .red-icon {
    width: 41px;
  }

  .projects-details-page .right-section-div {
    background: none;
    box-shadow: none;
    border: none;
    padding: 0;
  }

  .projects-details-page .top-right-projects {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.08);
    box-shadow: 0px 5px 20px rgb(0 0 0 / 6%);
    border-radius: 12px;
    display: inline-block;
    width: 100%;
    padding: 0 25px 25px;
  }

  .projects-details-page .middle-right {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.08);
    box-shadow: 0px 5px 20px rgb(0 0 0 / 6%);
    border-radius: 12px;
    display: inline-block;
    width: 100%;
    padding: 25px;
  }

  .right-small-icons li span:last-child {
    font-size: 25px;
    font-weight: 600;
    color: #000;
  }

  .right-small-icons li span:last-child small {
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    color: #5c5f62;
  }

  /* 24-05-2022 */
  .table-sortable th {
    cursor: pointer;
  }

  .table-sortable .th-sort-asc::after {
    content: "\25b4";
  }

  .table-sortable .th-sort-desc::after {
    content: "\25be";
  }

  .table-sortable .th-sort-asc::after,
  .table-sortable .th-sort-desc::after {
    margin-left: 5px;
  }

  .table-sortable .th-sort-asc,
  .table-sortable .th-sort-desc {
    background: rgba(0, 0, 0, 0.1);
  }

  .projects-scope .top-ps-seection .nav-pills .nav-link {
    width: 164px;
    margin: auto;
    text-align: center;
  }

  .client-temas-div {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.08);
    box-shadow: 0px 5px 20px rgb(0 0 0 / 6%);
    border-radius: 12px;
    display: inline-block;
    width: 100%;
    padding: 25px;
  }

  .client-teams-ul-li ul {
    margin: 0;
  }

  .client-teams-ul-li ul li {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .projects-details-page .client-teams-ul-li .teams-li figure {
    float: left;
    margin: 0 10px 0 0 !important;
  }

  .client-teams-ul-li .teams-li h5 {
    font-size: 16px;
    font-weight: 500;
    float: right;
  }

  .client-teams-ul-li .teams-li h5 span {
    font-size: 13px;
    font-weight: 400;
    color: #666666;
    padding-top: 5px;
  }

  .client-teams-ul-li .teams-li figure {
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 50%;
  }

  .add-pls svg {
    width: 12px;
    height: 12px;
  }

  .sp-dots1 {
    width: 25px;
  }

  .recente-vew-divs .comon-table-divs table {
    width: 100%;
  }

  .recente-vew-divs .comon-table-divs #ceo th {
    border-top: none !important;
  }

  .recente-vew-divs .comon-table-divs #ceo th:first-child {
    border: none !important;
  }

  .recente-vew-divs .collab-table #ceo th:first-child {
    border-bottom: 1px solid #d7d4d4 !important;
  }

  .recente-vew-divs .comon-table-divs #ceo th:last-child {
    width: 260px !important;
  }

  .recente-vew-divs .comon-table-td {
    color: #5746ec;
    text-decoration: none;
  }

  .sp-td-sec {
    box-shadow: none !important;
    background: none !important;
  }

  .td-inside {
    height: 145px;
  }

  .td-inside .icon-img {
    display: grid;
    align-content: center;
    margin-right: 10px;
  }

  .td-inside .bg-white {
    box-shadow: 0px 5px 20px rgb(0 0 0 / 6%);
    height: 145px;
    align-items: center;
    border-radius: 12px;
    padding: 0 20px;
    width: 100%;
    /* 28-04 */
  }

  .text-td {
    width: 379px;
  }

  .auto-td {
    width: 270px;
  }

  .commment-td {
    width: 120px;
  }

  .option-td {
    width: 223px;
  }

  .recente-vew-divs .w-300 {
    width: 339px;
  }

  .recente-vew-divs .w-160 {
    width: 270px;
  }

  .recente-vew-divs .w-1001 {
    width: 120px;
  }

  /* 24-05-2022 */
  .list-btn3 a,
  .monr-btn {
    background: #ffffff !important;
    border: 1.2px solid rgba(0, 0, 0, 0.15) !important;
    border-radius: 8px !important;
    height: 35px;
    font-size: 12px !important;
    margin-right: 9px;
    margin-bottom: 10px;
    font-weight: 500 !important;
    color: #5c5f62 !important;
    height: 33px;
    font-size: 13px !important;
  }

  /* 27-05-06 */
  .comon-tabs-expert th:nth-child(3),
  .body-expart-div
    .expert-tables
    .comon-table-divs
    table
    tbody
    td:nth-child(3) {
    width: 400px;
  }

  .comon-tabs-expert th:nth-child(2),
  .body-expart-div
    .expert-tables
    .comon-table-divs
    table
    tbody
    td:nth-child(2) {
    width: 160px;
  }

  /* ends */
  .monr-btn {
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    display: inline-block;
    text-decoration: none !important;
    color: #000;
    margin-bottom: 0 !important;
    position: relative;
    top: -5px;
  }

  .top-ps-seection .nav-pills .nav-link {
    width: 162px;
  }

  .w-300 {
    width: 293px;
  }

  .w-160 {
    width: 210px;
  }

  .w-1001 {
    width: 165px;
  }

  .w-2001 {
    width: 200px;
  }

  .w-66 {
    width: 60px !important;
  }

  .collab-table tr th:nth-child(2),
  .collab-table .bg-white > .div:first-child {
    width: 374px;
  }

  .expert-cell-page .comon-table-divs th:last-child,
  .expert-cell-page .comon-table-divs tr td:last-child {
    width: 155px;
  }

  .expert-cell-page .comon-table-divs th:nth-child(3),
  .expert-cell-page .comon-table-divs tr td:nth-child(3),
  .expert-cell-page .comon-table-divs th:nth-child(4) {
    width: 145px;
  }

  .comon-table-divs .dropdown .dropdown-item {
    font-size: 15px;
  }

  .user-mg-page .comon-table-divs th:last-child,
  .user-mg-page .comon-table-divs tr th:last-child:after {
    background: none !important;
  }

  .speacial-expert .modal-dialog {
    max-width: 550px !important;
  }

  .speacial-expert h2 {
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .speacial-expert hr {
    width: 94%;
  }

  .speacial-expert .modal-header {
    border: none !important;
    margin-bottom: 0;
    padding: 15px 20px 0;
  }

  .collab-table tr th:first-child,
  .collab-table .bg-white > .div:first-child {
    width: 430px;
  }

  .collab-table tr th:nth-child(2),
  .collab-table .bg-white > .div:first-child {
    width: 270px;
  }

  .recente-vew-divs .collab-table tr th:last-child,
  .recente-vew-divs .collab-table .bg-white > .div:last-child {
    width: 40px;
  }

  .call-history-table .td-inside .bg-white {
    width: 100%;
    padding: 0;
    align-items: flex-start;
    padding-top: 25px;
  }

  .call-history-table tr th:first-child {
    width: 557px;
  }

  .call-history-table .td-inside {
    margin-bottom: 25px;
  }

  .call-history-table .td-inside .bg-white > div:first-child {
    padding-left: 20px;
    width: 580px;
  }

  .call-history-table tr th:nth-child(2) {
    width: 150px;
  }

  .call-history-table tr th:nth-child(3) {
    width: 190px;
  }

  .call-history-table tr th:nth-child(4) {
    width: 190px;
  }

  .call-history-table .td-inside .bg-white > div:nth-child(2) {
    width: 155px;
    padding-left: 0;
  }

  .call-history-table .td-inside .bg-white > div:last-child {
    width: 201px;
    padding-left: 27px;
  }

  .call-history-table .commment-td {
    width: 155px;
  }

  .main-re-from-divs label {
    font-size: 16px;
  }
}

@media (max-width: 1440px) {
  .main-re-from-divs {
    margin-top: 110px !important;
  }

  .landing-sec-rg {
    margin-top: 30px !important;
  }

  .right-small-icons li span:last-child small {
    font-size: 12px;
    font-weight: 600;
  }
}

@media (max-width: 1400px) {
  .user-mg-page .comon-table-divs th:nth-child(3),
  .user-mg-page .comon-table-divs th:nth-child(4),
  .user-mg-page .comon-table-divs th:nth-child(5),
  .user-mg-page .comon-table-divs tr td:nth-child(3),
  .user-mg-page .comon-table-divs tr td:nth-child(4),
  .user-mg-page .comon-table-divs tr td:nth-child(5) {
    width: 148px;
  }

  .call-history-table .td-inside .bg-white {
    width: 100%;
    padding: 0;
    padding-top: 15px;
  }

  .call-history-table tr th:first-child {
    width: 442px;
  }

  .call-history-table .td-inside .bg-white > div:first-child {
    padding-left: 0px;
    width: 437px;
  }

  .call-history-table tr th:nth-child(2) {
    width: 150px;
  }

  .call-history-table tr th:nth-child(3) {
    width: 190px;
  }

  .call-history-table tr th:nth-child(4) {
    width: 190px;
  }

  .call-history-table .td-inside .bg-white > div:nth-child(2) {
    width: 150px;
    padding-left: 0;
  }

  .call-history-table .td-inside .bg-white > div:last-child {
    width: 201px;
    padding-left: 27px;
  }
}

@media (max-width: 1366px) {
  .main-re-from-divs label {
    font-size: 14px;
  }

  .conti-new {
    width: 450px;
    margin-left: 110px;
  }

  .landing-banner h1 {
    font-size: 53px;
    font-weight: 600;
  }

  .landing-banner,
  .home-banner {
    min-height: 645px;
  }

  /* 27-05-06 */
  .recente-vew-divs .comon-table-divs #ceo th:last-child {
    width: 180px !important;
  }

  .collab-table tr th:first-child,
  .collab-table .bg-white > .div:first-child {
    width: 390px;
  }

  .collab-table tr th:nth-child(2),
  .collab-table .bg-white > .div:first-child {
    width: 229px;
  }

  .td-inside .bg-white {
    width: 89%;
  }

  .call-history-table .td-inside .bg-white {
    width: 100%;
    padding: 0;
    padding-top: 24px;
  }

  .call-history-table tr th:first-child {
    width: 442px;
  }

  .call-history-table .td-inside .bg-white > div:first-child {
    padding-left: 0px;
    width: 437px;
  }

  .call-history-table tr th:nth-child(2) {
    width: 150px;
  }

  .call-history-table tr th:nth-child(3) {
    width: 190px;
  }

  .call-history-table tr th:nth-child(4) {
    width: 190px;
  }

  .call-history-table .td-inside .bg-white > div:nth-child(2) {
    width: 150px;
    padding-left: 0;
  }

  .call-history-table .td-inside .bg-white > div:last-child {
    width: 201px;
    padding-left: 27px;
  }

  .call-history-table .commment-td {
    width: 160px;
  }

  .collab-table .td-inside .bg-white {
    width: 82%;
    height: 116px;
  }

  .comon-tabs-expert th:nth-child(3),
  .body-expart-div
    .expert-tables
    .comon-table-divs
    table
    tbody
    td:nth-child(3) {
    width: 320px;
  }

  .comon-tabs-expert th:nth-child(2),
  .body-expart-div
    .expert-tables
    .comon-table-divs
    table
    tbody
    td:nth-child(2) {
    width: 120px;
  }

  /* ends */

  .projects-scope .top-ps-seection .nav-pills .nav-link {
    width: 145px;
    margin: auto;
    text-align: center;
  }

  .right-small-icons li span:last-child {
    font-size: 17px;
    font-weight: 600;
    color: #000;
  }

  .right-small-icons li span:last-child small {
    font-size: 11px;
    font-weight: 400;
    text-transform: uppercase;
    color: #5c5f62;
  }

  /* .select-option {
    margin-left: 51px;
  } */
  /* .top-bar-menu .serch-sec-div {
    width: 96%;
  } */
  .sp-hr {
    margin-left: 53px;
  }

  .sp-hr {
    margin-left: 53px;
  }

  /* .top-bar-menu {
    padding-right: 25px;
  } */

  /* .body-expart-div {
    padding-left: 20px;
    padding-right: 20px;
  } */

  .search-section-optios {
    padding-left: 53px;
  }

  .list-btn3 a,
  .monr-btn {
    background: #ffffff !important;
    border: 1.2px solid rgba(0, 0, 0, 0.15) !important;
    border-radius: 8px !important;
    height: 35px;
    font-size: 12px !important;
    margin-right: 3px;
  }

  .top-ps-seection .nav-pills .nav-link {
    width: 143px;
  }

  .w-300 {
    width: 257px;
  }

  .w-160 {
    width: 180px;
  }

  .w-1001 {
    width: 148px;
  }

  .w-2001 {
    width: 170px;
  }

  .w-66 {
    width: 66px !important;
  }
}

@media (max-width: 1199px) {
  .main-menu ul li a {
    font-size: 13px;
  }

  .main-menu ul li h5 {
    margin-left: 16px;
  }
}

@media (min-width: 1180px) {
  .projects-scope .top-ps-seection .nav-pills .nav-link {
    width: 140px;
    margin: 0 0;
  }
}

@media (min-width: 1281px) {
  .sidebar-menu {
    background: #fff;
    box-sizing: border-box;
    height: 100vh;
    left: 0;
    margin-top: 0;
    overflow: hidden;
    position: fixed;
    top: 0;
    width: 250px;
    z-index: 10;
    /* padding: 25px 25px 0; */
  }
}

@media (max-width: 1180px) {
  .comon-divs-re {
    height: auto;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .call-history-table tr th:first-child {
    width: 212px;
  }

  .call-history-table .td-inside .bg-white > div:first-child {
    padding-left: 21px;
    width: 234px;
  }

  .nes-tabs ul li {
    margin: 10px 0;
  }

  .td-inside .bg-white,
  .td-inside {
    height: auto;
  }

  .comon-history-expert {
    padding: 30px;
    height: auto;
  }

  .detsils-secch-comon ul li {
    margin: 10px 0;
  }

  .serhisiroy-right .edite-bnt {
    width: 100%;
    margin-left: 0;
  }

  .comon-divs-re h5 {
    font-size: 16px;
  }

  .body-expart-div .nav-pills .nav-link {
    margin-bottom: 20px;
  }

  .next-list li {
    margin-bottom: 10px;
  }

  .top-bar-menu .serch-sec-div {
    width: 100%;
  }

  .sp-hr {
    margin-left: 23px;
  }

  .body-expart-div {
    padding-left: 23px;
  }

  .main-menu ul li a {
    font-size: 13px;
  }

  .notes-d-section .comon-notes {
    margin-bottom: 25px;
  }
}

@media (max-width: 1024px) {
  .select-option,
  .sp-hr {
    margin-left: 0;
  }

  .body-expart-div {
    padding-left: 0;
  }

  .sidebar-menu {
    width: 200px;
  }
}

@media (max-width: 900px) {
  .sidebar-menu {
    display: none;
  }

  .landing-page-d1 .main-re-from-divs {
    margin: auto;
    width: 80%;
  }

  .comon-card-n {
    margin-bottom: 30px;
  }

  .new-register-page .main-re-from-divs,
  .new-add-rg2 .main-re-from-divs {
    margin: auto;
    width: 80%;
  }

  .new-register-page .conti-new {
    width: 80%;
    margin: auto;
    display: table;
  }

  .mob-call-sec .div-pogress h5 {
    font-size: 14px;
    color: #bbb;
  }

  .mob-call-sec .div-pogress h5 span {
    font-size: 18px;
    color: #000;
    font-weight: 600;
  }

  .mob-call-sec .date h5 {
    color: #5746ec;
    text-transform: capitalize;
  }

  .titels-new {
    font-size: 20px;
  }

  .mob-call-sec .nubers-div1 h5 {
    text-transform: capitalize;
    color: #000;
  }

  .detsils-secch-comon {
    width: 100%;
  }

  .landing-page-d1 .main-re-from-divs .logo,
  .new-register-page .main-re-from-divs .logo,
  .new-add-rg2 .main-re-from-divs .logo {
    margin-left: 0;
  }

  .comon-history-expert {
    width: 100%;
    margin: auto;
    height: auto;
    padding: 30px;
  }

  .serhisiroy-right:after {
    display: none;
  }

  .serhisiroy-right h5 {
    text-align: center;
  }

  .new-register-page .back-rgs {
    left: 8%;
  }

  .back-new-fun {
    left: 8%;
  }

  .serhisiroy-right .edite-bnt {
    margin: auto;
    width: 100%;
  }

  .left-hisroty-expert figure {
    width: 100%;
    text-align: left;
  }

  .detsils-secch-comon ul li {
    margin: 15px 0;
  }

  .serhisiroy-right {
    margin-top: 30px;
  }

  .mbile-divs-table {
    background: #ffffff;
    box-shadow: 0px 5px 20px rgb(0 0 0 / 6%);
    border-radius: 12px;
    padding: 30px;
    margin-top: 30px;
    display: inline-block;
    position: relative;
  }

  .inside-support,
  .news-section,
  .faq-section {
    width: 100%;
  }

  .body-expart-div h2 {
    font-size: 25px;
  }

  .top-bar-menu {
    width: 100%;
  }

  .main-menu ul li a {
    font-size: 15px;
  }

  .comon-divs-re h5 {
    font-size: 18px;
  }

  .content-wrapper {
    padding-left: 0;
  }

  .top-bar-menu .serch-sec-div {
    width: 100%;
  }

  .mobile-serch-sec-div {
    /* border: solid 1px #f33636 !important; */
    height: 43px !important;
    border-radius: 10px !important;
    padding-top: 0;
    padding-right: 10px;
    padding-bottom: 0;
    padding-left: 0;
    width: 100%;
    overflow: hidden;
    margin-bottom: 20px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff !important;
  }

  .mobile-serch-sec-div .form-control {
    border: none !important;
  }

  .mobile-serch-sec-div svg {
    color: #f33636;
  }

  .right-menu-sec > div:nth-child(2) {
    display: none !important;
  }

  .login-left-part,
  .regitser-page-div {
    padding-left: 30px;
  }

  .regitser-page-div {
    padding-right: 30px;
  }

  .select-option,
  .sp-hr,
  .body-expart-div {
    padding-left: 0;
  }

  .select-option,
  .sp-hr {
    margin-left: 0;
  }

  .comon-divs-re {
    height: auto;
    padding-top: 30px;
    padding-bottom: 30px;
    width: 100%;
  }

  .mobile-fill-width {
    width: 100%;
  }

  .mobile-canvas .offcanvas-title {
    visibility: hidden;
  }

  .next-list li {
    margin-bottom: 10px;
  }

  .tp-list1 {
    margin: 25px 0;
  }

  .new-reguest {
    line-height: 30px !important;
  }

  .right-menu-sec {
    justify-content: space-between;
  }

  .top-bar-menu > div {
    width: 100%;
  }

  .left-section-divs,
  .right-section-div {
    width: 100%;
  }

  .search-section-optios {
    padding-left: 0;
  }

  .search-section-optios .comon-ser-btn {
    margin-bottom: 10px;
  }

  .tab-content {
    overflow: auto;
    width: 100%;
  }

  .comon-table-divs table {
    table-layout: fixed;
    width: 1090px !important;
  }

  .top-ps-seection .nav {
    background: #e7e7e7;
    border-radius: 10px;
    padding: 5px 5px 1px;

    justify-content: space-between;
  }

  .collab-table {
    overflow: auto;
    width: 100%;
  }

  .collab-table .table {
    table-layout: fixed;
    width: 800px !important;
  }

  .request-page {
    width: 100%;
  }

  /* 02-06-2022 */
  .login-page-div {
    position: relative;
    width: 50%;
    padding-bottom: 50px;
  }

  .mobile-canvas .offcanvas-header,
  .mobile-canvas .logo-admin {
    display: none;
  }

  .mobile-canvas {
    top: 62px !important;
  }

  .dasb-div .select-option {
    width: 100%;
    overflow: auto;
  }

  .dasb-div .select-option .flex-column {
    flex-direction: row !important;
    width: 1366px;
  }

  .body-expart-div .nav-pills .nav-link {
    font-size: 13px;
    padding: 8px;
  }

  .body-expart-div .nav-item {
    margin: 0 1px;
  }

  .body-expart-div .ps-titel {
    font-size: 20px;
  }

  .td-inside .d-flex {
    flex-wrap: wrap !important;
    width: 100% !important;
    justify-content: flex-start !important;
    height: auto !important;
    padding: 20px !important;
  }

  .td-inside .text-td {
    width: 100%;
  }

  .collaborate-page .td-inside {
    height: auto !important;
    margin-bottom: 30px;
    display: inline-block !important;
    width: 100%;
  }

  .file-btn {
    margin-right: 10px;
  }

  .body-expart-div h2 {
    font-size: 22px;
  }

  .user-mg-page h2 {
    margin-bottom: 0;
  }

  .top-ps-seection {
    width: 100%;
  }

  .request-body-sec ul.d-flex {
    flex-wrap: wrap !important;
  }

  .request-body-sec ul.d-flex li {
    width: 100%;
    margin-bottom: 15px;
  }

  .details-message-part .expert-tables .nav {
    flex-wrap: nowrap !important;
    justify-content: flex-start !important;
  }

  .expert-tables {
    width: 100%;
    overflow: auto;
  }

  .message-text-area .text-area-type {
    width: 90%;
  }

  .search-mobile-s {
    display: block !important;
  }

  .mboile-logo {
    text-decoration: none;
    height: 32px;
    overflow: hidden;
    top: 2px;
    position: relative;
    display: inline-block;
  }

  .comon-re-texts {
    display: none;
  }

  .expert-login-page .landing-sec-rg {
    display: table;
    width: 80%;
    margin: auto;
  }

  .expert-login-page .logo {
    margin-left: 0;
  }

  .all-complains {
    width: 100%;
  }

  .payment-details-2 {
    width: 100%;
  }

  .comon-payments-ex {
    width: 100%;
  }
}

@media (max-width: 667px) {
  .comon-select {
    margin-bottom: 25px;
  }

  .mob-call-sec .date h4 {
    color: #5c5f62;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 500;
  }

  .comon-payments-ex .dropdown {
    position: absolute;
    top: 28px;
    right: 10px;
  }

  .request-body-sec ul li {
    margin-bottom: 10px;
  }

  .jodit-toolbar__box:not(:empty) {
    width: 92% !important;
  }

  .md-titel-link {
    display: block;
  }

  .request-body-sec ul li span,
  .request-body-sec .com-li li span:first-child {
    margin-right: 10px;
  }

  .online-st svg {
    color: #299c46;
  }

  .expert-login-page .main-re-from-divs {
    padding-left: 0;
    padding-right: 0;
  }

  .landing-page-d1 .main-re-from-divs {
    padding-right: 15px;
  }

  .all-chat-divs .menmber-text,
  .all-chat-divs .admin-text {
    width: 105%;
  }

  .expert-cell-page .comon-table-td {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .all-chat-divs {
    padding: 0;
  }

  .comon-toggle {
    flex-direction: row-reverse;
  }

  .cm-modal-all .submit-bn {
    width: 100%;
  }

  .comon-toggle p {
    position: relative;
    left: 10px;
  }

  .mob-call-sec .div-pogress h4 {
    color: #5c5f62;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 500;
  }

  .mob-call-sec .date h5 {
    font-size: 18px;
  }

  .left-pm {
    align-items: baseline;
  }

  .mob-call-sec .nubers-div1 h5 {
    font-size: 18px;
  }

  .mob-call-sec .comon-table-td {
    font-size: 20px;
  }

  .mob-call-sec .nubers-div1 h4 {
    color: #5c5f62;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 500;
  }

  .all-complains {
    width: 100%;
  }

  .serhisiroy-right {
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    padding-top: 15px;
  }

  .payment-details-2 {
    width: 100%;
  }

  .left-pm figure {
    margin-bottom: 15px;
  }

  .comon-payments-ex {
    height: auto;
  }

  .comon-re-texts {
    display: none;
  }

  .left-hisroty-expert figure {
    width: 100%;
    text-align: left;
  }

  .expert-login-page .landing-sec-rg {
    display: table;
    width: 80%;
    margin: auto;
  }

  .expert-login-page .row > div:nth-child(2) {
    padding-right: 0;
  }

  .expert-login-page .logo {
    margin-left: 0;
  }

  .landing-page-d1 .logo {
    margin-left: 0;
    border-bottom: solid 1px rgba(0, 0, 0, 15%);
    padding-bottom: 20px;
    text-align: center;
  }

  .converstion-divs .nav .nav-link h5 span {
    color: #5c5f62;
    font-weight: 400;
    font-size: 13px;
  }

  .body-expart-div .nav-pills .nav-link {
    margin-bottom: 0 !important;
  }

  .top-ps-seection .nav {
    flex-direction: column;
  }

  .body-expart-div .nav-pills .nav-link {
    width: 100%;
  }

  .body-expart-div .nav-pills .nav-link.active,
  .body-expart-div .nav-pills .nav-link:hover,
  .body-expart-div .nav-pills .show > .nav-link {
    width: 100%;
  }

  .body-expart-div .nav-pills .nav-link {
    margin-bottom: 15px;
  }

  .next-list li {
    margin-bottom: 10px;
  }

  .tp-list1 {
    margin: 25px 0;
  }

  .regitser-page-div {
    padding-right: 0;
    padding-left: 0;
  }

  .notes-d-section .comon-notes {
    margin-bottom: 25px;
  }

  .request-page {
    width: 100%;
  }

  .login-page-div {
    height: 100%;
    width: 100%;
  }

  .login-page-div .inside-login {
    margin-bottom: 50px;
  }

  .mbile-divs-table {
    background: #ffffff;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    padding: 30px;
    margin-top: 30px;
    display: inline-block;
    position: relative;
  }

  .mbile-divs-table h5 {
    text-transform: uppercase;
    color: #5c5f62;
  }

  .number-list {
    justify-content: flex-start;
  }

  .comon-table-td {
    color: #000000;
    text-decoration: none;
  }

  .dp-mobiles {
    position: absolute;
    right: 20px;
    top: 37px;
  }
}

@media (max-width: 480px) {
  .login-page-div {
    height: auto;
  }

  .login-page-div .inside-login {
    margin-bottom: 0;
  }

  .main-re-from-divs {
    width: 100% !important;
    padding-left: 25px;
    padding-right: 25px;
    margin-top: 60px !important;
  }

  .back-new-fun,
  .back-rgs {
    top: 84px;
    left: 6%;
  }

  .new-register-page .back-rgs {
    left: 3% !important;
  }

  .comon-dt-picker {
    margin-top: 10px;
  }

  .last-details h5 {
    text-align: left !important;
  }

  .dasb-div .select-option .flex-column {
    flex-direction: row !important;
    width: 762px;
  }

  .dasb-div .select-option .flex-column > div {
    padding-right: 3px !important;
  }

  .next-list li {
    font-size: 12px;
    color: #5c5f62;
  }

  .next-list ul {
    display: flex;
    flex-wrap: wrap;
  }

  .next-list li {
    display: none;
  }

  .more-ex-btn {
    width: 100%;
    /* margin-top: 15px; */
    margin-left: 0;
  }

  .tp-list1 li {
    font-size: 16px;
    margin-bottom: 10px;
    flex-wrap: wrap;
  }

  .one-ex-btn {
    width: 100%;
  }

  .notes {
    flex-wrap: wrap;
  }

  .speacial-expert h2 {
    font-size: 22px;
  }

  .notes span:last-child {
    padding-left: 0;
    margin-left: 0;
    margin-top: 8px;
  }

  .next-list li a {
    text-decoration: none;
    color: #5c5f62;
    font-size: 16px;
  }

  .next-list li:first-child,
  .next-list li:last-child {
    display: block;
  }

  .add-btn-divs {
    margin-top: 10px;
  }

  .new-register-page .row > div:nth-child(2) {
    padding: 0;
  }

  .re-spi-logo {
    border-bottom: solid 1px rgba(0, 0, 0, 15%);
    padding-bottom: 20px;
    width: 100%;
    text-align: center;
    margin: auto;
    margin-top: 15px;
  }

  .new-register-page .conti-new {
    margin-bottom: 25px;
    width: 86%;
  }

  .regitser-page-div {
    width: 100%;
  }

  .login-page-div {
    margin-top: 23px;
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
  }

  .login-btn1 {
    display: block;
  }

  .regitser-page-div .inside-login p {
    font-size: 16px;
  }

  .comon-fild label,
  .regitser-page-div label {
    color: #000000;
  }

  .regitser-page-div .form-control::-webkit-input-placeholder {
    color: #000;
  }

  .inside-login h4 {
    text-align: center;
    font-size: 26px;
    font-weight: 500;
    margin-top: 30px;
  }

  .register-btn {
    display: block;
    font-size: 20px;
  }

  .inside-login p,
  .landing-sec-rg p {
    font-size: 20px;
  }

  .main-re-from-divs label {
    font-size: 18px;
  }

  .main-re-from-divs .form-control::-webkit-input-placeholder {
    color: #000;
  }

  .expert-login-page .landing-sec-rg {
    width: 100%;
  }

  .landing-sec-rg {
    margin-top: 23px !important;
  }

  .expert-login-page .login-fm-div {
    padding-left: 25px;
    padding-right: 15px;
  }

  .comon-select {
    margin-bottom: 25px;
  }

  .body-expart-div .nav-pills .nav-link {
    margin-bottom: 15px;
  }
}

@media (max-width: 1024px) {
  .content-wrapper {
    padding-left: 200px;
  }

  .main-menu .mainMenuUL {
    width: 200px;
  }
}

@media (max-width: 991px) {
  .content-wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* @media files from custom.css */

@media only screen and (max-width: 1900px) {
  .select-option .upperULSelectOne {
    padding-left: 0;
  }

  .select-option .ais-RefinementList-list {
    width: 26.9%;
  }

  .select-option .input_Range_Slider {
    padding-left: 50px;
    padding-right: 50px;
    position: absolute;
    width: 100%;
    top: 23%;
  }

  .select-option .input_Range_Slider .DefaultProgressBar_progressBar {
    height: 5px;
  }

  .select-option .input_Range_Slider .DefaultHandle_handle {
    border-radius: 50%;
  }

  .select-option .input_Range_Slider .DefaultHandle_handle__horizontal {
    top: -9px;
  }

  .select-option .input_Range_Slider .DefaultBackground_background__horizontal {
    height: 9px;
    border-radius: 5px;
  }

  .select-option .input_Range_Slider .rheostat-value {
    width: 75px;
  }
}

@media only screen and (max-width: 1440px) {
  .select-option .ais-RefinementList-list {
    width: 24.4%;
  }

  .body-expart-div,
  .select-option,
  .customized-app {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media only screen and (max-width: 1366px) {
  .select-option .ais-RefinementList-list {
    width: 23.7%;
  }
}

@media (max-width: 1360px) {
  .body-expart-div,
  .select-option,
  .customized-app {
    padding-left: 20px;
    padding-right: 20px;
  }
}
/* @media from Sidebar.css */
@media (max-width: 1024px) {
  .userProfileWrapper {
    width: 19%;
    overflow: hidden;
  }
}

@media (max-width: 991px) {
  .mainMenuUL li h5 {
    font-size: 16px;
  }
}
/* @media from expDashboard Login.css */
@media only screen and (max-width: 991px) {
  .loginPage {
    max-width: 500px;
    margin: auto;
  }

  .loginPage a {
    text-align: center;
  }

  .loginPage img {
    max-width: 200px;
    margin: auto;
  }
}

/*from expDashboard login.css From Register.css */
@media only screen and (max-width: 991px) {
  .login-page {
    overflow-y: auto;
    height: auto;
  }

  .scrollableCls {
    height: auto;
  }
}

/* from Pages.css */
@media (max-width: 1440px) {
  .responsivePaymentBox {
    height: 210px;
  }

  .ant-segmented-item-label,
  .projects-scope .nav-link {
    font-size: 14px !important;
  }
}

@media (max-width: 1200px) {
  .fixedCol-2 {
    width: 100% !important;
    border-top: 1px solid #d3d3d3;
    margin-top: 1.5rem;
    padding-top: 1.5rem;
    padding-left: 1.5rem;
  }

  .fixedCol-2:after {
    content: none !important;
  }
}

@media (max-width: 991px) {
}

@media (max-width: 767px) {
  .fixedCol-2 {
    width: 100%;
    text-align: center;
  }

  .responsivePaymentBox {
    height: auto;
  }
}

@media (max-width: 991px) {
  .fixedWidthCol {
    margin: 0 0 16px 0;
  }
}

/* @media from pages/Dashboard.css */
@media (min-width: 991px) {
  .clock-wrapper {
    width: 100%;
  }

  .clock-wrapper .clock-row,
  .clock-wrapper .clock-row .card {
    width: 100%;
  }
}

@media (min-width: 1199px) {
  .clock-wrapper {
    overflow-y: unset;
  }
}
/* @media from expertDashboard/Register.css */
@media only screen and (max-width: 991px) {
  .react-tel-input .country-list {
    width: 93.5vw;
  }

  .login-page {
    overflow-y: auto;
    height: auto;
  }
  .registerPage {
    max-width: 500px;
    margin: auto;
  }

  .registerPage a {
    text-align: center;
  }

  .registerPage img {
    max-width: 200px;
    margin: auto;
  }

  .scrollableCls {
    height: auto;
  }

  :where(.css-dev-only-do-not-override-14wwjjs).ant-input-lg,
  :where(.css-dev-only-do-not-override-14wwjjs).ant-picker-large
    .ant-picker-input
    > input {
    font-size: 14px;
  }
}

/* @media from invoice.css */

@media (max-width: 767px) {
  .invoice-container {
    padding: 1rem;
  }
}

/* @media from QCExperts/ExpertProfil.css */
@media (max-width: 768px) {
  .experienceWrapper .experienceInfo:before {
    background-color: transparent;
  }

  .experienceWrapper .experienceInfo:after {
    height: 0;
    background-color: transparent;
  }

  .experienceWrapper {
    padding-left: 0;
    margin-top: 16px;
  }

  .experienceWrapper .experienceInfo {
    padding-left: 0;
  }
}

/* @media from AddMeeting.css */
@media (min-width: 991px) {
  .zoom-form-body {
    padding-inline: 30px;
    background-color: white;
    border-radius: 10px;
  }

  .zoom_draft_drawer .ant-drawer-content-wrapper {
    max-width: 1000px;
    width: 80% !important;
  }

  .fsfe input {
    height: 7px !important;
  }
}

@media (max-width: 576px) {
  .registerPage {
    max-width: 95vw;
    margin: auto;
  }
}
