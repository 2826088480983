.logo-bottom {
  font-size: 17px;
  color: "#5c5f62";
}

.logo-bottom,
span {
  color: "#5746ec";
}

.profileSidebar {
  display: flex;
  align-items: center;
  background-color: #e5e5e5;
  padding: 10px 10px 10px 7px;
  border-radius: 15px;
  width: 100%;
}

.profileSidebar img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.profileSidebarDetails {
  margin-left: 10px;
  max-width: 143px;
}
.profileSidebarDetails h5 {
  color: #222;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 1;
  /* display: -webkit-box; */
  -webkit-box-orient: vertical;
}

.profileSidebarDetails p {
  color: #808080;
  font-size: 11px;
  margin-bottom: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 1;
  /* display: -webkit-box; */
  -webkit-box-orient: vertical;
}

.userProfileWrapper {
  position: fixed;
  width: 250px;
  background: #fff;
  left: 0;
  padding: 15px;
  bottom: 0;
}

.user_Profiles {
  border: solid 1.2px #d9d9d9;
  border-radius: 10px;
  overflow: hidden;
}

.profilePopover {
  position: relative;
  left: 260px;
}
.positibsw {
  box-shadow: 0 0 12px rgb(0 0 0 / 30%);
  padding: 15px 15px;
  background: #fff;
  border-radius: 15px;
  width: 100%;
  z-index: 999;
  position: fixed;
  max-width: 250px;
  bottom: -30px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;
}

.positibsw.active {
  visibility: visible;
  opacity: 1;
  bottom: 15px;
}

.profileUl {
}

.profileUl li {
  margin: 5px 0;
}

.profileUl li a {
  padding: 5px 10px;
  color: #000;
}

.profileUl li a:hover {
  color: rgb(87, 70, 236);
}

.mainMenuUL li .menu-text {
  font-size: 14px;
  font-weight: 500;
}

.mainMenuUL svg {
  font-size: 20px;
  margin-right: 10px;
}

.sidebar-menu .sidebarBadge {
  position: absolute;
  right: 10px;
}
.sidebar-menu .sidebarBadge1 {
  position: absolute;
  right: 10px;
}

.sidebarBadge {
  width: 27px;
  height: 27px;
  border-radius: 50%;
  background-color: #22ffab;
  color: #000;
  text-align: center;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebarBadge1 {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #22ffab;
  color: #000;
  text-align: center;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.subMenuHover {
  position: relative;
}

.subMenuHover .subMenu {
  overflow: hidden;
  max-height: 0;
  height: auto;
  background-color: #fff;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
  list-style: none;
  padding: 0;
}

.subMenuHover:hover .subMenu {
  max-height: 1000px;
}

.mobileMenu {
  padding: 0px 10px;
  background: #fff;
}

.mobileHamburger {
  cursor: pointer;
}

.mobileMenuBody .mainMenuUL li a {
  display: block;
  width: 100%;
  margin-bottom: 10px;
}

.menuBarOffCanvas {
  width: 100%;
  top: 0;
}

.customAntPop .ant-popover-content {
  left: 200px !important;
  top: 60px !important;
}
